import * as configcat from "configcat-js-ssr";
import { getEnvironment } from "@utils/common";

import type { IConfigCatClient } from "configcat-common";
import type { MeQuery } from "@generated/graphql";
import type { OpCo } from "@utils/get-opco-with-locale";
import type { FeatureFlag } from "@components/user-context/user-context";
import type { TFunction } from "i18next";

export type IFeatures = configcat.SettingKeyValue[];

const OpCoToClientSet = new Map<OpCo, IConfigCatClient>();

export const isMeDataAlphaOrBeta = (meData?: MeQuery["me"] | undefined): boolean => {
  if (!meData) {
    return false;
  }

  return (
    meData.roles.find(
      (r) => r.toLowerCase().includes("alpha") || r.toLowerCase().includes("beta"),
    ) !== undefined
  );
};

const initConfigCatClient = (opCo: OpCo) => {
  let configCatKey: string | undefined = undefined;
  const environment = getEnvironment();

  // will retrieve all the feature flags but also IDP Settings based on Opco
  if (process.env.NODE_ENV === "development" && !process.env.NEXT_PUBLIC_OVERRIDE_OPCO) {
    configCatKey = process.env.NEXT_PUBLIC_CONFIGCAT_KEY_DEV;
  } else if (environment === "staging") {
    configCatKey = process.env.NEXT_PUBLIC_CONFIGCAT_KEY_STAGING;
  } else {
    switch (opCo) {
      case "liber":
        configCatKey = process.env.NEXT_PUBLIC_CONFIGCAT_KEY_LIBER;
        break;
      case "plantyn":
        configCatKey = process.env.NEXT_PUBLIC_CONFIGCAT_KEY_PLANTYN;
        break;
      default:
        configCatKey = process.env.NEXT_PUBLIC_CONFIGCAT_KEY_JUNIORCLOUD;
        break;
    }
  }

  if (!configCatKey) {
    throw `Missing ConfigCatKey for ${opCo}`;
  }

  const configCatClient = configcat.getClient(configCatKey, configcat.PollingMode.LazyLoad, {
    cacheTimeToLiveSeconds: 600,
  });

  OpCoToClientSet.set(opCo, configCatClient);
  return configCatClient;
};

export const getAllValues = async (
  opCo: OpCo,
  meData?: MeQuery["me"] | undefined,
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  custom: any = {},
  configTranslation?: TFunction,
  cookie?: { key: string; value: string }[],
): Promise<FeatureFlag[]> => {
  let configCatClient = OpCoToClientSet.get(opCo);

  if (!configCatClient) {
    configCatClient = initConfigCatClient(opCo);
  }

  if (!configCatClient) {
    return [];
  }

  // biome-ignore lint/suspicious/noImplicitAnyLet: unknown type
  let userObject;
  if (meData) {
    const roles = meData.roles.map((role) => `<${role}>`).toString();
    userObject = { identifier: meData.id, custom: { roles, ...custom } };
  } else {
    const roles: string = "[]";
    userObject = { identifier: "", custom: { roles, ...custom } };
  }
  const settingValues = await configCatClient.getAllValueDetailsAsync(userObject);

  const featureFlags = settingValues.map((settingKeyValue) => {
    // Check if we have a translation/description for a key, if we do it is a toggle and we use the cookie value (if set)
    let isToggle = false;
    if (
      configTranslation &&
      settingKeyValue.value === true &&
      settingKeyValue.matchedTargetingRule &&
      configTranslation(`toggles.${settingKeyValue.key}.name`, "") !== "" &&
      isMeDataAlphaOrBeta(meData)
    ) {
      isToggle = true;
      settingKeyValue.value = cookie?.find((x) => x.key === settingKeyValue.key)?.value || false;
    }

    return {
      key: settingKeyValue.key,
      value: settingKeyValue.value,
      isToggleable: isToggle,
    };
  });

  return featureFlags;
};
