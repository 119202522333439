import { PlatformError } from "@components/platform-error/platform-error";
import { PlatformErrorCodes } from "@components/platform-error/platform-error-types";
import { Shape1, Shape2, Shape3 } from "@components/svg/shape-bg-path/shape-bg-path.styles";
import { Button, type Theme, useTheme } from "@infinitaslearning/pixel-design-system";
import { useTranslation } from "next-i18next";
import type { FC } from "react";

type UnauthProps = {
  errorMessage: string;
  ctaText: string;
  ctaUrl: string;
  // biome-ignore lint/suspicious/noExplicitAny: We don't know the type of the object
  error?: any;
};

const primary20 = (theme: Theme) =>
  theme.pixel?.color.palette.primary[20] ?? theme.palette.primary.dark;

const UnauthErrorComponent: FC<UnauthProps> = ({ errorMessage, ctaText, ctaUrl, error }) => {
  const theme = useTheme();
  const { t } = useTranslation("unauth");

  return (
    <PlatformError
      displayType="full"
      errorType="unauthenticated"
      errorCode={PlatformErrorCodes.Forbidden}
      customTitle={t("unauth.error-title")}
      customMessage={errorMessage}
      CustomCTA={
        <Button size="medium" variant="text" href={ctaUrl}>
          {ctaText}
        </Button>
      }
      error={error}
    >
      <Shape1 strokeColor={primary20(theme)} />
      <Shape2 strokeColor={primary20(theme)} />
      <Shape3 strokeColor={primary20(theme)} />
    </PlatformError>
  );
};

export default UnauthErrorComponent;
