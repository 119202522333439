import { Box } from "@infinitaslearning/pixel-design-system";
import type React from "react";
import { Main } from "src/styles/global-styling/global.styles";

type LayoutProps = {
  children?: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Main>
      <Box height="100%" width="100%">
        {children}
      </Box>
    </Main>
  );
};

export default Layout;
