import { type FC, memo } from "react";

export type ShapeBgPathProps = {
  strokeColor: string;
  className?: string;
};

const ShapeBgPath1: FC<ShapeBgPathProps> = ({ strokeColor, className }) => (
  // biome-ignore lint/a11y/noSvgWithoutTitle: No need to add a title for this background element
  <svg width={323} height={383} className={className}>
    <path
      d="M5.16-81.124s0 0 0 0C-9.82-35.012 7.888 93.542 58.286 304.541c10.725 44.901 50.793 76.626 96.957 76.767 52.387.16 98.597-13.687 138.63-41.543 89.195-62.063 140.692-169.36 122.105-239.246-9.463-35.576-29.806-66.35-61.03-92.322h0a100 100 0 00-55.608-22.771c-27.752-2.323-55.325-12.777-82.72-31.362-20.22-13.719-61.196-54.103-78.296-65.103-7.63-4.908-15.954-9.705-24.973-14.392h0c-37.544-19.512-83.796-4.893-103.308 32.65A76.611 76.611 0 005.16-81.123z"
      stroke={strokeColor}
      fill="none"
      opacity={0.12}
    />
  </svg>
);

const MemoShapeBgPath1 = memo(ShapeBgPath1);
export default MemoShapeBgPath1;
